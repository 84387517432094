@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

html {
  font-family: "Lato", system-ui, sans-serif;
  @apply text-gray-800;
}

body {
  @apply bg-slate-50;
}

h1 {
  @apply font-bold text-2xl md:text-3xl;
}

h2 {
  @apply font-bold text-xl md:text-2xl;
}

h3 {
  @apply font-bold text-lg md:text-xl;
}

h4 {
  @apply font-bold text-base md:text-lg;
}

p {
  @apply text-base md:text-lg;
}

h5 {
  @apply font-bold text-gray-800 text-sm md:text-base;
}

.hint,
small {
  @apply text-gray-500 text-sm;
}

.print-header {
  @apply text-gray-800 text-xl font-bold;
}
.print-normal {
  @apply text-gray-800 text-sm;
}
.print-separate-page {
  page-break-before: always;
  page-break-after: always;
}

#reader__dashboard_section_csr button {
  @apply bg-caribbean-green-700 text-white p-4 rounded;
}

.page {
  @apply pb-36 md:pb-20;
}

.p-toast-message {
  margin-left: -22px;
}

.text-primary {
  @apply text-gray-800;
}
.text-secondary {
  @apply text-gray-500;
}
.bg-primary {
  @apply bg-white;
}
.bg-secondary {
  @apply bg-gray-800;
}

// OVERRIDES
.p-inplace-content {
  display: flex;
}
.p-inplace-display{
  height: 2rem;
  width: 100%;
}
.inline-text.start {
  .p-inplace-display{
    display: flex;
    justify-content: start;
    height: 2rem;
    width: 100%;    
  }
}
.inline-text.center {
  .p-inplace-display{
    display: flex;
    justify-content: center;
    height: 2rem;
    width: 100%;    
  }
}