/*
 * Replace this with your own classes
 *
 * e.g.
 * .request-print {
 * }
*/

@page {
  size: A4;
}