/*
 * Replace this with your own classes
 *
 * e.g.
 * .print-inventory-qr {
 * }
*/
@page {
    size: 3in 5in landscape;
    margin: 0;
}
@media print {
@page {
    size: 3in 5in landscape;
}
}