/*
 * Replace this with your own classes
 *
 * e.g.
 * .print-ics-form {
 * }
*/
@page {
    size: A4;
    margin: 0;
}