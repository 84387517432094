/*
 * Replace this with your own classes
 *
 * e.g.
 * .print-inventory-equipment {
 * }
*/
.thead {
    @apply px-2 py-1;

    td, th{
        @apply border-l border-r px-2 text-center;
    }
}

.tdata {
    @apply px-2 py-1 border;
}

@page {
    size: landscape;
}